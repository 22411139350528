<template lang="pug">
    .sign-form
        h3.form-title.font-green Авторизация
        .alert.alert-danger(v-if="isNotCorrectUser") Пользователь не найден!
        .form-group
            label.control-label Логин
            input.form-control.form-control-solid.placeholder-no-fix(
                v-model="authData.login" placeholder="Логин" maxlength="50")
        .form-group
            label.control-label Пароль
            input.form-control.form-control-solid.placeholder-no-fix(
                v-model="authData.password" type="password" placeholder="Пароль" maxlength="50")
        .alert.alert-block.alert-danger(v-if="errors.length")
            h4.alert-heading Заполните все обязательные поля!
            p(v-for="error in errors" :key="error") {{ error }}
        .form-actions
            a.btn.green.uppercase.pull-right(:disabled="disabledBtn" @click="signIn")
                | Войти
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'SignInForm',
        data: () => ({
            test: 'hi bro!',
            disabledBtn: false,
            isNotCorrectUser: false,
            authData: {
                login: '',
                password: ''
            }
        }),
        computed: {
            errors() {
                const result = []
                if (!this.authData.login.length) result.push('- заполните поле "логин"')
                else if (!this.isValidEmail(this.authData.login)) result.push('- укажите корректный адрес электронной почты')
                if (!this.authData.password.length) result.push('- заполните поле "пароль"')
                return result
            }
        },
        methods: {
            async signIn() {
                this.disabledBtn = true
                const response = await axios
                    .post(`${location.origin}/webServices/UserController.asmx/AuthorizationUser`, this.authData)
                    .then(x => x.data.d)
                this.disabledBtn = false

                if(response.code === 200) window.location = response.redirect
                else if (response.code === 203) this.isNotCorrectUser = true
            },
            isValidEmail(email) {
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return re.test(email)
            }
        }
    }
</script>